<template>
  <div></div> 
</template>

<script>
export default {
  beforeCreate() {
    this.$router.push('/ci/wbs/list')
  }
}
</script>

<style>

</style>
